import 'lightgallery.js';
import 'lg-thumbnail.js';

class Gallery
{
    static getSelector()
    {
        return '.gallery';
    }

    /**
     * @param element HTMLElement
     */
    constructor(element)
    {
        lightGallery(element, {
            selector: '.gallery-item',
            thumbnail: element.dataset.thumbnail === 'true',
            download: element.dataset.download === 'true',
            currentPagerPosition: 'middle',
        });

        element.addEventListener('onBeforeOpen', function() {
            document.documentElement.classList.add('is-clipped');
        });

        element.addEventListener('onBeforeClose', function() {
            document.documentElement.classList.remove('is-clipped');
        });
    }

}

export default Gallery;
